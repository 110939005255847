import React, {useEffect, useRef} from 'react';
import {
    setRascoApplicationWizardState
} from "../../../../../features/rascoApplicationWizard/rascoApplicationWizardSlice";
import {useAppDispatch, useAppSelector} from "../../../../../app/store";
import {IRascoApplicationWizardState} from "../../../../../app/types/redux/IRascoApplicationWizardState";
import cl from './CargoWizard.module.css';
import {useTranslation} from "react-i18next";
import Button, {ButtonType} from "../../../../../components/ui/Button/Button";
import {selectRascoApplication} from "../../../../../features/rascoApplication/rascoApplicationSelectors";
import {
    addOrderItem, deleteOrderItem, updateOrderItem
} from "../../../../../features/rascoApplication/rascoApplicationSlice";
import {IRascoApplicationOrderItem} from "../../../../../app/types/redux/IRascoApplicationState";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus, faTrash} from "@fortawesome/free-solid-svg-icons";
import WizardInput from "../../components/WizardInput/WizardInput";
import OrderItemContentSelector from "./components/OrderItemContentSelector/OrderItemContentSelector";
import {NumericTextBoxComponent} from "@syncfusion/ej2-react-inputs";
import uuid from 'react-uuid';
import OrderItemClientSelector from './components/OrderItemClientSelector/OrderItemClientSelector';
import {selectAuthUser} from "../../../../../features/authentication/authenticationSelectors";

const footerInitialState: IRascoApplicationWizardState = {
    isPreviousButtonDisabled: false,
    showPreviousButton: true,
    isNextButtonDisabled: true,
    showNextButton: true,
    isCancelButtonDisabled: false,
    showCancelButton: true,
    showProgressBar: true,
    showFooter: true,
    cancelButtonAlign: 'left',
    nextButtonAlign: 'right',
    previousButtonAlign: 'right'
}

function validateOrderItemsArray(orderItems?: Array<IRascoApplicationOrderItem>): boolean {
    return orderItems!.every(item => {
        return item.shipmentIdentificationNumber !== undefined && item.shipmentIdentificationNumber !== null && item.shipmentIdentificationNumber !== ''
            && item.amount !== undefined && item.amount >= 1 && item.amount <= 10000
            && item.weight !== undefined && item.weight >= 1 && item.weight < 100000
            && item.orderItemContentTypeId !== undefined && item.orderItemContentTypeId !== null
            && item.content !== undefined && item.content !== null
    });
}

function isValid(orderItems?: Array<IRascoApplicationOrderItem>): boolean {
    if (orderItems === undefined || orderItems === null || orderItems.length <= 0)
        return false;

    return validateOrderItemsArray(orderItems);
}

const CargoWizard = () => {
    const dispatch = useAppDispatch();
    const {t} = useTranslation();
    const {orderItems} = useAppSelector(selectRascoApplication);
    const tableContainerRef = useRef<null | HTMLDivElement>(null);
    const tableRef = useRef<null | HTMLTableElement>(null);
    const orderItemsArrLenght = useRef<number>(0);
    const user = useAppSelector(selectAuthUser);

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(footerInitialState));
    }, [dispatch]);

    useEffect(() => {
        dispatch(setRascoApplicationWizardState(
            isValid(orderItems)
                ? {...footerInitialState, isNextButtonDisabled: false}
                : footerInitialState
        ));

        if (orderItems !== undefined) {
            if (orderItemsArrLenght.current < orderItems.length &&
                tableContainerRef !== null && tableContainerRef.current !== null &&
                tableRef !== null && tableRef.current !== null &&
                tableRef.current.clientHeight > tableContainerRef.current.clientHeight) {
                const elements = tableRef.current.querySelectorAll('[data-main="true"]');
                if (elements !== null && elements !== undefined && elements.length > 0) {
                    const lastElem = Array.from(elements).pop();

                    if (lastElem !== null && lastElem !== undefined) {
                        lastElem.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'});
                    }
                }
            }
            orderItemsArrLenght.current = orderItems.length
        }
    }, [dispatch, orderItems]);

    const addNewOrderItem = () => {
        dispatch(addOrderItem({
            position: orderItems === undefined || orderItems.length <= 0
                ? 1
                : Math.max(...orderItems.map(item => item.position)) + 1,
            shipmentIdentificationNumber: '',
            uuid: uuid()
        }));
    }

    return (
        <div className={cl.container}>
            <div>
                {t("application.cargo-wizard.header-title")}
            </div>
            <div className={cl.contentContainer}>
                <div>
                    <div className={cl.contentLabel}>
                        <b>
                            {t("application.cargo-wizard.header-title-2")}
                        </b>
                    </div>
                    <div>
                        <Button buttonType={ButtonType.Primary}
                                id={'add-cargo-btn'}
                                onClick={addNewOrderItem}
                                style={{padding: '0'}}
                                disabled={orderItems !== undefined && orderItems !== null && orderItems.length >= 100}>
                            <div className={cl.btn}>
                                    <span>
                                        <FontAwesomeIcon icon={faPlus}/>
                                    </span>
                                <span>
                                        {t("btn.new")}
                                    </span>
                            </div>
                        </Button>
                    </div>
                </div>
                <div ref={tableContainerRef}>
                    <table className={cl.table} ref={tableRef}>
                        <thead>
                        <tr>
                            <th scope="col"
                                style={{width: '50px'}}>
                                {t("application.cargo-wizard.position")}
                            </th>
                            <th scope="col" className={'required'}>
                                {t("application.cargo-wizard.shipment-number")}
                            </th>
                            <th scope="col" className={'required'}>
                                {t("application.cargo-wizard.amount")}
                            </th>
                            <th scope="col" className={'required'}>
                                {t("application.cargo-wizard.weight")}
                            </th>
                            <th scope="col" className={'required'}>
                                {t("application.cargo-wizard.content")}
                            </th>
                            {user && user.companyBranchItemId == 5584 && <th scope="col" className={''}>
                                {t("application.cargo-wizard.client")}
                            </th>}
                            {user && user.companyBranchItemId == 5584 &&<th scope="col" className={''}>
                                {t("application.cargo-wizard.cost")}
                            </th>}
                            <th scope="col" style={{width: '50px'}}></th>
                        </tr>
                        </thead>
                        <tbody>
                        {orderItems !== undefined && orderItems.length > 0
                            ? orderItems.map((item: IRascoApplicationOrderItem) => {
                                return (
                                    <React.Fragment key={item.uuid}>
                                        {item.refuseNotes !== undefined && item.refuseNotes !== null && item.refuseNotes.length > 0
                                            ? <tr data-row={'rejected-row'}
                                                  className={cl.refuseNotesRow}>
                                                <td colSpan={6}>
                                                    {item.refuseNotes}
                                                </td>
                                            </tr>
                                            : null
                                        }
                                        <tr className={item.refuseNotes !== undefined && item.refuseNotes !== null && item.refuseNotes.length > 0 ? cl.refusedBorder : ''}
                                            data-main={true}>
                                            <td data-label={t("application.cargo-wizard.position")}>
                                                <div>
                                                    {item.position}
                                                </div>
                                            </td>
                                            <td data-label={t("application.cargo-wizard.shipment-number")}>
                                                <div className={cl.inputContainer}>
                                                    <WizardInput showClearButton={false}
                                                                 id={`shipment-identification-number-${item.position}`}
                                                                 value={item.shipmentIdentificationNumber}
                                                                 toUpperCase={true}
                                                                 onChange={(ev) => {
                                                                     dispatch(updateOrderItem({
                                                                         ...item,
                                                                         shipmentIdentificationNumber: ev.target.value ? ev.target.value.toUpperCase() : ev.target.value,
                                                                         updateField: 'shipmentIdentificationNumber'
                                                                     }))
                                                                 }}
                                                                 placeholder={t("application.cargo-wizard.shipment-number")}/>
                                                    {item.shipmentIdentificationNumber === undefined || item.shipmentIdentificationNumber === null || item.shipmentIdentificationNumber === ''
                                                        ? <div className='server-error-label'
                                                               style={{textAlign: 'left'}}>
                                                            {t("shared.errors.required-error").replace('{0}', t("application.cargo-wizard.shipment-number"))}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </td>
                                            <td data-label={t("application.cargo-wizard.amount")}>
                                                <div
                                                    className={`${cl.inputContainer} syncfusion-control-input-container`}>
                                                    <NumericTextBoxComponent min={1}
                                                                             id={`amount-${item.position}`}
                                                                             format='###'
                                                                             max={10000}
                                                                             validateDecimalOnType={true}
                                                                             decimals={0}
                                                                             value={item.amount}
                                                                             change={(ev) => {
                                                                                 dispatch(updateOrderItem({
                                                                                     ...item,
                                                                                     amount: ev.value,
                                                                                     updateField: 'amount'
                                                                                 }));
                                                                             }}
                                                                             placeholder={t("application.cargo-wizard.amount")}/>
                                                    {item.amount === undefined || item.amount === null
                                                        ? <div className='server-error-label'
                                                               style={{textAlign: 'left'}}>
                                                            {t("shared.errors.required-error").replace('{0}', t("application.cargo-wizard.amount"))}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </td>
                                            <td data-label={t("application.cargo-wizard.weight")}>
                                                <div
                                                    className={`${cl.inputContainer} syncfusion-control-input-container`}>
                                                    <NumericTextBoxComponent min={1}
                                                                             id={`weight-${item.position}`}
                                                                             format='###.#'
                                                                             max={100000}
                                                                             validateDecimalOnType={true}
                                                                             decimals={1}
                                                                             value={item.weight}
                                                                             change={(ev) => {
                                                                                 dispatch(updateOrderItem({
                                                                                     ...item,
                                                                                     weight: ev.value,
                                                                                     updateField: 'weight'
                                                                                 }));
                                                                             }}
                                                                             placeholder={t("application.cargo-wizard.weight")}/>
                                                    {item.weight === undefined || item.weight === null
                                                        ? <div className='server-error-label'
                                                               style={{textAlign: 'left'}}>
                                                            {t("shared.errors.required-error").replace('{0}', t("application.cargo-wizard.weight"))}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </td>
                                            <td data-label={t("application.cargo-wizard.content")}>
                                                <div
                                                    className={`${cl.inputContainer} syncfusion-control-input-container`}>
                                                    <OrderItemContentSelector orderItem={item}/>
                                                    {!item.content
                                                        ? <div className='server-error-label'
                                                               style={{textAlign: 'left'}}>
                                                            {t("shared.errors.required-error").replace('{0}', t("application.cargo-wizard.content"))}
                                                        </div>
                                                        : null
                                                    }
                                                </div>
                                            </td>
                                            {user && user.companyBranchItemId == 5584 &&<td data-label={t("application.cargo-wizard.client")}>
                                                <div
                                                    className={`${cl.inputContainer} syncfusion-control-input-container`}>
                                                    <OrderItemClientSelector orderItem={item}/>

                                                </div>
                                            </td>}
                                            {user && user.companyBranchItemId == 5584 &&<td data-label={t("application.cargo-wizard.cost")}>
                                                <div className={cl.inputContainer}>
                                                    <WizardInput showClearButton={false}
                                                                 id={`cost-${item.position}`}
                                                                 value={item.cost}
                                                                 toUpperCase={true}
                                                                 onChange={(ev) => {
                                                                     dispatch(updateOrderItem({
                                                                         ...item,
                                                                         cost: ev.target.value ? ev.target.value.toUpperCase() : ev.target.value,
                                                                         updateField: 'cost'
                                                                     }))
                                                                 }}
                                                                 placeholder={t("application.cargo-wizard.cost")}/>
                                                    <div className='server-info-label'
                                                         style={{textAlign: 'left'}}>
                                                        {item.costNotes}
                                                    </div>
                                                </div>
                                            </td>}
                                            <td className={cl.deleteIcon}>
                                                <FontAwesomeIcon onClick={() => dispatch(deleteOrderItem(item))}
                                                                 id={`delete-btn-${item.position}`}
                                                                 icon={faTrash}/>
                                            </td>
                                        </tr>
                                        <tr className={cl.separatorRow}></tr>
                                    </React.Fragment>
                                )
                            })
                            : null
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default CargoWizard;
