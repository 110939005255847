import {ICompanyBranchItem} from "../models/ICompanyBranchItem";
import {IApiErrorResourceModelItem} from "../../apiError/IApiErrorResourceModelItem";
import {ResponseResultCode} from "../enums/ResponseResultCode";
import {TransportType} from "../enums/TransportType";

export interface IRascoApplicationState {
    id?: number;
    companyBranchItemId?: number | null;
    companyBranchItem?: ICompanyBranchItem | null;
    confirmation?: string;
    email?: string;
    orderDate?: string;
    employeeName?: string;
    truckLicencePlate?: string;
    trailerLicencePlate?: string;
    doubleTrailerLicencePlate?: string;
    city?: string;
    sealNumber?: string;
    loadingDate?: string;
    loadingTime?: string;
    arrivalDate?: string;
    arrivalTime?: string;
    orderItems?: Array<IRascoApplicationOrderItem>;
    utcOffset?: number;
    arrivalDt?: Date;
    loadingDt?: Date;
    orderDt?: Date;
    transportType?: TransportType;
}

export interface IRascoApplicationOrderItem {
    id?: number;

    uuid?: string;
    position: number;
    shipmentIdentificationNumber?: string;
    amount?: number;
    weight?: number;
    content?: string;
    orderItemContentTypeId?: number;
    refuseNotes?:string;
    clientId?:number;
    client?:string;
    cost?:string;
    costNotes?:string
}

export const rascoApplicationApiErrors: Array<IApiErrorResourceModelItem> = [
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'confirmation',
        resourceName: 'application.submit-wizard.errors.not-valid-data-confirmation'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'email',
        resourceName: 'application.submit-wizard.errors.not-valid-data-email'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'orderDate',
        resourceName: 'application.submit-wizard.errors.not-valid-data-order-date'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'employeeName',
        resourceName: 'application.submit-wizard.errors.not-valid-data-employee-name'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'truckLicencePlate',
        resourceName: 'application.submit-wizard.errors.not-valid-data-truck-licence-plate'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'city',
        resourceName: 'application.submit-wizard.errors.not-valid-data-city'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'sealNumber',
        resourceName: 'application.submit-wizard.errors.not-valid-data-seal-number'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'arrivalDate',
        resourceName: 'application.submit-wizard.errors.not-valid-data-arrival-date'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'arrivalTime',
        resourceName: 'application.submit-wizard.errors.not-valid-data-arrival-time'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'loadingDate',
        resourceName: 'application.submit-wizard.errors.not-valid-data-loading-date'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'loadingTime',
        resourceName: 'application.submit-wizard.errors.not-valid-data-loading-time'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'orderDate',
        resourceName: 'application.submit-wizard.errors.not-valid-data-order-date'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'loadingDate',
        description: 'parse',
        resourceName: 'application.submit-wizard.errors.not-valid-data-loading-date-parse'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'loadingTime',
        description: 'parse',
        resourceName: 'application.submit-wizard.errors.not-valid-data-loading-time-parse'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'arrivalDate',
        description: 'parse',
        resourceName: 'application.submit-wizard.errors.not-valid-data-arrival-date-parse'
    },
    {
        resultCode: ResponseResultCode.NotValidData,
        field: 'arrivalTime',
        description: 'parse',
        resourceName: 'application.submit-wizard.errors.not-valid-data-arrival-time-parse'
    },
];
